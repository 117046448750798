export const logoNegative = ['112.82 108.55', `
  <title>SDS2030 Logo</title>
  <style type="text/css">
	.st0{clip-path:url(#SVGID_2_);fill:#EB5C2A;}
	.st1{clip-path:url(#SVGID_2_);fill:#F08224;}
	.st2{clip-path:url(#SVGID_2_);fill:#F7A81F;}
	.st3{clip-path:url(#SVGID_2_);fill:#F39222;}
	.st4{clip-path:url(#SVGID_2_);fill:#EF7C2B;}
	.st5{clip-path:url(#SVGID_2_);fill:#F5A252;}
	.st6{clip-path:url(#SVGID_2_);fill:#FAB31F;}
	.st7{clip-path:url(#SVGID_2_);fill:#EF7B25;}
	.st8{clip-path:url(#SVGID_2_);fill:#F28E38;}
	.st9{clip-path:url(#SVGID_2_);fill:#FAB61B;}
	.st10{clip-path:url(#SVGID_2_);fill:#FBBC19;}
	.st11{clip-path:url(#SVGID_2_);fill:#FCBD19;}
	.st12{clip-path:url(#SVGID_2_);fill:#E6233C;}
	.st13{clip-path:url(#SVGID_2_);fill:#D71941;}
	.st14{clip-path:url(#SVGID_2_);fill:#AE1833;}
	.st15{clip-path:url(#SVGID_2_);fill:#DD223B;}
	.st16{clip-path:url(#SVGID_2_);fill:#ED6F25;}
	.st17{clip-path:url(#SVGID_2_);fill:#E84138;}
	.st18{clip-path:url(#SVGID_2_);fill:#EB5A51;}
	.st19{clip-path:url(#SVGID_2_);fill:#E6233B;}
	.st20{clip-path:url(#SVGID_2_);fill:#AC2126;}
	.st21{clip-path:url(#SVGID_2_);fill:#E84248;}
	.st22{clip-path:url(#SVGID_2_);fill:#E62147;}
	.st23{clip-path:url(#SVGID_2_);fill:#EA5426;}
	.st24{clip-path:url(#SVGID_2_);fill:#85171D;}
	.st25{clip-path:url(#SVGID_2_);fill:#661316;}
	.st26{clip-path:url(#SVGID_2_);fill:#E94540;}
	.st27{clip-path:url(#SVGID_2_);fill:#0B96B5;}
	.st28{clip-path:url(#SVGID_2_);fill:#03677D;}
	.st29{clip-path:url(#SVGID_2_);fill:#10B5C0;}
	.st30{clip-path:url(#SVGID_2_);fill:#54BEC7;}
	.st31{clip-path:url(#SVGID_2_);fill:#1EB6C4;}
	.st32{clip-path:url(#SVGID_2_);fill:#00ABC3;}
	.st33{clip-path:url(#SVGID_2_);fill:#039BB4;}
	.st34{clip-path:url(#SVGID_2_);fill:#00A9C2;}
	.st35{clip-path:url(#SVGID_2_);fill:#06879D;}
	.st36{clip-path:url(#SVGID_2_);fill:#027D8E;}
	.st37{clip-path:url(#SVGID_2_);fill:#05687A;}
	.st38{clip-path:url(#SVGID_2_);fill:#055675;}
	.st39{clip-path:url(#SVGID_2_);fill:#027A90;}
	.st40{clip-path:url(#SVGID_2_);fill:#0688A3;}
</style>
<g>
	<g>
		<defs>
			<path id="SVGID_1_" d="M59.65,39.53c-1.21-0.71-1.69-1.64-2.44-2.82c0.38-0.31,0.77-0.63,1.15-0.94c0.96-0.77,1.76-2.02,2.4-3.1
				c2.48-4.15,3.84-9.83,7.91-12.37c0.58-0.36,1.16-0.4,1.88-0.7c0.59,0,0.99,0.13,1.43,0.24c0.07,0.41-0.24,1.07-0.35,1.5
				c-0.17,1.06-0.35,2.11-0.52,3.17c-0.13,1.72-0.26,3.44-0.38,5.16c-0.58,3.54-1.18,6.75-3.45,8.57c-1.16,0.93-3.15,1.93-5.14,1.93
				C61.29,40.18,60.43,39.99,59.65,39.53 M51.22,28.28c-1.23-6.13,1.69-11.14,5.26-13.24c0.84-0.49,1.88-0.82,2.93-1.08
				c0.39-0.06,0.79-0.12,1.18-0.17c1.98,0,3.43,0.25,4.67,0.98c0.3,0.2,0.6,0.39,0.91,0.59c-0.23,0.08-0.46,0.16-0.7,0.24
				c-0.63,0.27-1.17,0.71-1.67,1.11c-1.67,1.36-3.03,4.56-4.08,6.62c-1.7,3.33-4.15,7.24-7.49,8.88
				C51.71,31.07,51.49,29.64,51.22,28.28 M83.72,6.13c-0.59,0.07-1.18,0.14-1.78,0.21c-1.06,0.23-2.05,0.54-2.96,0.94
				c-2.88,1.27-4.79,3.66-5.75,6.86c-0.14,0.8-0.28,1.6-0.42,2.4c-0.01,0.46-0.02,0.93-0.03,1.39c-0.13,0.46-0.47,0.86-0.56,1.39
				c-0.17-0.33-0.35-0.65-0.52-0.98c-0.46-0.72-0.93-1.44-1.39-2.16c-1.85-2.6-4.3-5.09-7.38-6.44c-0.88-0.38-1.82-0.68-2.86-0.91
				c-2.46-0.52-4.9,0.32-6.37,1.19c-2.91,1.71-4.8,3.85-5.71,7.56c-0.24,0.98-0.22,2.04-0.38,3.13c0.03,0.89,0.07,1.79,0.1,2.68
				c0.43,2.81,0.62,5.38,1.43,7.77c0.31,0.75,0.63,1.51,0.94,2.26c-5.26,0.73-6.27-4.18-7.25-8.01c-0.28-1.1-0.39-2.37-0.8-3.27
				c-0.07-0.03-0.14-0.07-0.21-0.1c-1.2,0.7-2.83,3.52-3.52,4.88c-0.62,1.22-1.03,2.55-1.32,4.14c-0.43,2.39,0.21,4.96,0.77,6.69
				c1.35,4.17,3.9,6.64,7.39,8.67c1.24,0.72,2.61,1.18,3.97,1.78c9.22,4.02,9.35,16.26,7.67,24.61c-1.21,6.02-3.38,11.78-5.17,17.64
				c0,0.43,0.21,1.28,0.21,1.07c0.75-1.09,1.46-2.2,2.14-3.34c5.18-8.63,8.67-18.53,7.42-28.73c-0.71-5.77-2.72-10.9-7.66-14.26
				c-1.59-1.08-3.31-1.98-5.07-2.75c-2.55-1.11-6.58-2.55-8.19-4.49c-2.12-2.58-0.08-7,1.88-8.43h0.03c0.9,3.86,3.61,8.62,7.21,9.72
				c0.99,0.3,2.28,0.15,3.31,0.04c0.29,0.88,0.9,1.66,1.39,2.37c1.58,2.26,3.34,4.63,5.57,6.24h0.04c0.01-0.05,0.02-0.09,0.03-0.14
				c-0.49-0.77-0.97-1.53-1.46-2.3c-1.4-2.24-2.81-4.54-3.76-7.18c0.64-0.38,1.28-0.77,1.92-1.15c0.3,0.82,0.6,1.65,0.91,2.47
				c0.68,1.56,1.68,2.9,2.68,4.15c2.63,3.28,6.59,3.17,9.44,0.21c2.11-2.19,2.95-5.52,3.66-9.13c0.13-1.15,0.26-2.3,0.38-3.45
				c0.05-1.72,0.09-3.44,0.14-5.16c0.08-0.66,0.16-1.32,0.24-1.99h0.07c1.04,2.69,5.11,5.37,8.99,5.29c0.27-0.81,0.62-1.59,0.8-2.58
				c0.75-4.15-1.76-9.66-3.17-12.09c-0.45-0.78-1.59-3.28-2.4-3.48c-1.4,0.99-2.64,5.15-1.25,6.93c0.32,0.41,1.02,0.94,1.85,0.8
				c0.33-0.05,0.95-0.16,1.32-0.03c1.24,0.4,2.7,3.95,1.81,5.85v0.07c-2.26-0.88-4.26-2.01-5.43-3.97
				c-1.31-2.19-1.15-5.02-0.17-7.42c1.64-4.04,4.89-6.33,10.21-6.72V6.86c-0.39-0.63-1.34-0.74-2.37-0.74
				C84.11,6.12,83.92,6.12,83.72,6.13"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
		</clipPath>
		<polygon class="st0" points="98.09,-8.86 59.29,22.33 14.15,35.57 		"/>
		<polygon class="st1" points="98.09,-8.86 90.91,13.06 59.29,22.33 		"/>
		<polygon class="st2" points="33.6,25.28 20.87,8.21 47.34,18 		"/>
		<polygon class="st3" points="33.6,25.28 43.97,26.83 59.29,22.33 		"/>
		<polygon class="st4" points="59.29,22.33 47.34,18 33.6,25.28 		"/>
		<polygon class="st3" points="51.93,8.18 98.09,-8.86 47.34,18 		"/>
		<polygon class="st5" points="27.9,6.15 51.93,8.18 35.64,10.87 		"/>
		<polygon class="st3" points="47.34,18 35.64,10.87 51.93,8.18 		"/>
		<polygon class="st6" points="54.46,2.78 62.36,4.33 47.34,18 		"/>
		<polygon class="st7" points="44,7.51 54.46,2.78 51.93,8.18 		"/>
		<polygon class="st8" points="54.46,2.78 27.9,6.15 44,7.51 		"/>
		<polygon class="st9" points="27.9,6.15 40.06,-2.42 41.9,4.38 		"/>
		<polygon class="st10" points="37.12,28.84 33.6,25.28 43.97,26.82 		"/>
		<polygon class="st11" points="27.9,6.15 20.88,8.21 47.34,18 		"/>
		<polygon class="st3" points="51.93,8.18 62.36,4.33 54.46,2.78 		"/>
		<polygon class="st12" points="59.87,53.03 11.36,36.38 43.98,26.81 		"/>
		<polygon class="st13" points="123.95,34.23 66.57,44.4 59.87,53.03 		"/>
		<polygon class="st14" points="59.29,22.32 59.87,53.03 43.98,26.81 		"/>
		<polygon class="st15" points="37.42,45.32 43.98,26.81 11.36,36.38 		"/>
		<polygon class="st16" points="90.92,13.04 59.87,53.03 59.29,22.32 		"/>
		<polygon class="st17" points="123.95,34.23 71.66,37.84 66.57,44.4 		"/>
		<polygon class="st18" points="65.14,57.97 59.87,53.03 41.08,65.03 		"/>
		<polygon class="st12" points="123.95,34.23 81.25,25.49 71.66,37.84 		"/>
		<polygon class="st19" points="59.87,53.03 65.14,57.97 83.36,46.14 		"/>
		<polygon class="st20" points="97.19,28.75 81.25,25.49 90.92,13.04 		"/>
		<polygon class="st21" points="66.57,44.4 59.73,45.61 59.87,53.03 		"/>
		<polygon class="st22" points="59.73,45.61 66.57,44.4 59.61,38.94 		"/>
		<polygon class="st23" points="71.66,37.84 59.61,38.94 66.57,44.4 		"/>
		<polygon class="st24" points="81.1,53.29 123.95,34.23 83.36,46.14 		"/>
		<polygon class="st25" points="65.14,57.97 83.36,46.14 81.1,53.29 		"/>
		<polygon class="st26" points="41.08,65.03 37.42,45.32 59.87,53.03 		"/>
		<polygon class="st12" points="37.42,45.32 41.08,65.03 31.84,67.74 		"/>
		<polygon class="st27" points="49.73,72.6 75.21,65.13 62.42,65.05 		"/>
		<polygon class="st28" points="70.26,60.49 62.42,65.05 75.21,65.13 		"/>
		<polygon class="st29" points="83.15,60.76 77.81,56.9 75.21,65.13 		"/>
		<polygon class="st30" points="55.01,76.21 83.15,60.76 75.25,90.09 		"/>
		<polygon class="st31" points="83.15,60.76 104.97,72.61 75.25,90.09 		"/>
		<polygon class="st32" points="22.15,107.08 75.25,90.09 117.13,79.22 		"/>
		<polygon class="st33" points="55.01,76.21 22.15,107.08 75.25,90.09 		"/>
		<polygon class="st34" points="79.64,73.81 75.25,90.09 55.01,76.21 		"/>
		<polygon class="st29" points="29.23,67.64 38.57,64.9 17.61,78.16 		"/>
		<polygon class="st35" points="104.97,72.61 75.25,90.09 117.13,79.22 		"/>
		<polygon class="st36" points="75.21,65.13 79.03,53.03 66.29,56.77 		"/>
		<polygon class="st30" points="49.73,72.6 17.61,78.16 38.57,64.9 		"/>
		<polygon class="st37" points="66.29,56.77 70.26,60.49 38.57,64.9 		"/>
		<polygon class="st38" points="62.42,65.05 70.26,60.49 38.57,64.9 		"/>
		<polygon class="st39" points="55.01,76.21 49.73,72.6 75.21,65.13 		"/>
		<polygon class="st40" points="62.42,65.05 38.57,64.9 49.73,72.6 		"/>
	</g>
	<path d="M101.77,74.24c0-2.32-0.16-4.19-0.53-5.81c-0.25-1.1-0.6-2.16-1.24-3.11c-0.59-0.87-1.36-1.52-2.4-1.78
		c-1.75-0.45-3.72-0.05-4.95,1.88c-0.48,0.75-0.8,1.57-1.03,2.42c-0.48,1.76-0.62,3.56-0.66,5.38c-0.04,2.18,0.01,4.35,0.45,6.49
		c0.25,1.24,0.62,2.44,1.35,3.5c0.63,0.92,1.46,1.55,2.57,1.79c1.97,0.42,3.69-0.3,4.76-2.01c0.5-0.8,0.82-1.67,1.04-2.58
		C101.64,78.38,101.76,76.31,101.77,74.24 M107.47,73.66c0,3.03-0.2,5.54-0.84,7.99c-0.37,1.41-0.88,2.76-1.64,4.01
		c-1.66,2.71-4.08,4.18-7.22,4.51c-1.46,0.16-2.9,0.09-4.32-0.25c-2.39-0.58-4.24-1.93-5.58-4c-1.06-1.65-1.68-3.47-2.06-5.38
		c-0.54-2.67-0.65-5.37-0.54-8.09c0.09-2.25,0.37-4.47,1.05-6.62c0.37-1.19,0.87-2.32,1.56-3.37c1.63-2.49,3.95-3.83,6.87-4.19
		c1.5-0.18,3-0.12,4.47,0.23c2.36,0.56,4.2,1.86,5.54,3.88c1.05,1.57,1.66,3.32,2.06,5.16C107.31,69.72,107.47,71.93,107.47,73.66"
		/>
	<path d="M35.94,74.14c0.02,1.77,0.09,3.49,0.41,5.19c0.24,1.33,0.63,2.61,1.35,3.77c1.66,2.67,5.69,2.67,7.34-0.02
		c0.46-0.76,0.8-1.56,1.04-2.42c0.45-1.62,0.61-3.29,0.67-4.96c0.09-2.39,0.02-4.76-0.46-7.11c-0.23-1.12-0.58-2.2-1.21-3.17
		c-0.59-0.92-1.37-1.6-2.44-1.88c-1.73-0.46-3.72-0.08-4.96,1.86c-0.54,0.84-0.87,1.76-1.11,2.72
		C36.06,70.11,35.97,72.14,35.94,74.14 M30.24,74.1c0.03-2.56,0.22-5.15,0.92-7.68c0.34-1.24,0.8-2.42,1.46-3.53
		c1.67-2.82,4.16-4.33,7.41-4.65c1.44-0.14,2.86-0.07,4.27,0.27c2.47,0.6,4.35,2.02,5.69,4.17c1.05,1.7,1.64,3.57,2,5.51
		c0.57,3.14,0.63,6.31,0.34,9.49c-0.18,2-0.55,3.96-1.27,5.85c-0.44,1.15-1.01,2.23-1.78,3.2c-1.34,1.69-3.06,2.76-5.16,3.23
		c-1.79,0.4-3.59,0.4-5.39,0.02c-2.47-0.53-4.38-1.87-5.77-3.97c-1.07-1.62-1.69-3.43-2.09-5.31C30.4,78.54,30.25,76.35,30.24,74.1"
		/>
	<path d="M14.25,67.18H9.19c-0.01-0.14-0.03-0.29-0.04-0.44c0-0.85,0.01-1.69,0-2.54c-0.01-1.24,0.43-2.3,1.3-3.17
		c0.41-0.41,0.86-0.82,1.37-1.09c3.58-1.98,7.32-2.41,11.15-0.87c3.98,1.61,5.82,5.3,5.42,9.31c-0.22,2.16-1.12,4-2.59,5.57
		c-0.91,0.98-1.95,1.82-3.08,2.52c-1.27,0.79-2.58,1.53-3.87,2.3c-0.92,0.55-1.84,1.09-2.74,1.67c-0.67,0.43-1.27,0.95-1.76,1.59
		c-0.44,0.57-0.73,1.21-0.75,1.95c-0.01,0.56,0.18,0.8,0.73,0.88c0.19,0.03,0.38,0.03,0.57,0.03c2.88,0,5.77,0,8.65,0
		c0.09,0,0.17,0,0.26,0c0.58-0.03,0.89-0.33,0.91-0.91c0.02-0.45,0.01-0.9,0.01-1.35c0-0.17,0-0.34,0-0.54h5.07
		c0.01,0.17,0.03,0.32,0.03,0.47c0,1.4,0.01,2.8,0,4.19c0,0.39-0.04,0.79-0.12,1.18c-0.19,0.95-0.8,1.51-1.74,1.69
		c-0.37,0.07-0.75,0.1-1.13,0.11c-5.3,0.01-10.6,0.01-15.9,0c-0.5,0-1-0.07-1.48-0.18c-0.73-0.16-1.21-0.64-1.43-1.36
		c-0.11-0.38-0.2-0.77-0.24-1.16c-0.34-3.58,0.67-6.65,3.42-9.06c1.05-0.93,2.21-1.72,3.41-2.43c1.1-0.65,2.21-1.28,3.31-1.93
		c1.01-0.6,1.99-1.24,2.86-2.04c0.87-0.8,1.54-1.72,1.82-2.89c0.19-0.77,0.22-1.54,0.02-2.31c-0.37-1.41-1.3-2.29-2.67-2.7
		c-1.24-0.38-2.5-0.34-3.71,0.12c-0.46,0.17-0.89,0.45-1.28,0.76c-0.48,0.38-0.73,0.9-0.69,1.54
		C14.26,66.43,14.25,66.79,14.25,67.18"/>
	<path d="M69,66.35h-5.08c-0.01-0.19-0.03-0.34-0.03-0.49c0-1.4-0.01-2.8,0.01-4.19c0.01-0.48,0.06-0.97,0.17-1.43
		c0.18-0.81,0.73-1.27,1.53-1.43c0.32-0.06,0.65-0.1,0.98-0.1c5.42-0.01,10.84,0,16.26,0c0.12,0,0.24,0.01,0.42,0.02
		c0.01,0.17,0.03,0.33,0.03,0.5c0,0.85-0.01,1.69,0.01,2.54c0.01,0.33-0.08,0.59-0.29,0.85c-2.12,2.66-4.22,5.33-6.33,8
		c-0.13,0.16-0.25,0.32-0.41,0.53c0.3,0.07,0.52,0.12,0.75,0.18c1.43,0.34,2.75,0.93,3.88,1.87c2.5,2.05,3.47,4.74,3.21,7.91
		c-0.33,4.1-3.24,8.05-8.49,8.94c-4.58,0.78-8.64-0.46-12.16-3.49c-0.22-0.19-0.43-0.4-0.68-0.64c0.95-1.43,1.88-2.83,2.84-4.27
		c0.15,0.13,0.28,0.23,0.4,0.34c1.67,1.5,3.58,2.52,5.82,2.85c1.28,0.19,2.55,0.14,3.76-0.37c2.13-0.9,3.24-3.08,2.67-5.24
		c-0.26-1-0.85-1.76-1.69-2.34c-1.04-0.72-2.21-1.05-3.45-1.1c-0.71-0.03-1.42-0.01-2.12-0.02c-0.17,0-0.34,0-0.55,0
		c-0.18-0.44-0.35-0.87-0.52-1.3c-0.15-0.38-0.29-0.78-0.46-1.15c-0.14-0.29-0.08-0.5,0.1-0.75c1.54-2.06,6.97-9.11,6.97-9.11h-0.2
		c0,0-4,0.07-6,0.1c-1.1,0.01-1.32,0.22-1.32,1.34C69,65.36,69,65.84,69,66.35"/>
	<polygon points="10.81,102.11 7.71,92.94 9.75,92.94 11.75,99.38 13.74,92.94 15.73,92.94 12.62,102.11 	"/>
	<rect x="16.1" y="92.94" width="1.92" height="9.17"/>
	<path d="M23.83,95.65c-0.1-0.94-0.66-1.39-1.84-1.39c-1.06,0-1.63,0.37-1.63,1.12c0,0.52,0.43,0.88,1.42,1.12
		c1.17,0.28,2,0.5,2.72,0.8c0.83,0.34,1.44,0.91,1.44,2.37c0,1.75-1.57,2.76-3.47,2.76c-2.65,0-4-1.05-4.07-3.07h1.85
		c0.01,0.93,0.86,1.52,1.97,1.52c1.2,0,1.85-0.48,1.85-1.23c0-0.61-0.25-0.98-1.42-1.23c-2.8-0.59-4.04-1.09-4.04-3.06
		c0-1.52,1.12-2.72,3.38-2.72c2.21,0,3.59,1.2,3.66,3.01H23.83z"/>
	<rect x="26.34" y="92.94" width="1.92" height="9.17"/>
	<path d="M35.66,97.65c0-2.17-0.88-3.31-2.48-3.31c-1.61,0-2.51,1.15-2.51,3.31c0,2.17,0.92,3.13,2.51,3.13
		C34.55,100.78,35.66,99.82,35.66,97.65 M28.77,97.61c0-3.11,1.59-4.97,4.4-4.97c2.82,0,4.38,1.86,4.38,5.01
		c0,3.15-1.56,4.78-4.38,4.78C30.36,102.43,28.77,100.7,28.77,97.61"/>
	<polygon points="43.77,102.11 39.85,95.95 39.85,102.11 38.07,102.11 38.07,92.94 40.1,92.94 43.74,98.84 43.74,92.94 45.54,92.94
		45.54,102.11 	"/>
	<path d="M56.35,97.65c0-2.17-0.88-3.31-2.48-3.31c-1.61,0-2.51,1.15-2.51,3.31c0,2.17,0.93,3.13,2.51,3.13
		C55.24,100.78,56.35,99.82,56.35,97.65 M49.46,97.61c0-3.11,1.59-4.97,4.4-4.97c2.82,0,4.38,1.86,4.38,5.01
		c0,3.15-1.56,4.78-4.38,4.78C51.05,102.43,49.46,100.7,49.46,97.61"/>
	<polygon points="60.68,98.24 60.68,102.11 58.76,102.11 58.76,92.94 65.29,92.94 65.29,94.54 60.68,94.54 60.68,96.63 64.71,96.63
		64.71,98.24 	"/>
	<polygon points="69.07,102.11 69.07,92.94 75.81,92.94 75.81,94.54 70.93,94.54 70.93,96.5 75.42,96.5 75.42,98.12 70.93,98.12
		70.93,100.48 76.03,100.48 76.03,102.11 	"/>
	<path d="M83.18,102.11l-0.04-0.91c-0.44,0.77-1.49,1.23-2.75,1.23c-2.54,0-4.15-2.36-4.15-4.91c0-2.71,1.55-4.87,4.51-4.87
		c2.18,0,3.86,1.3,4,3.13h-1.93c-0.1-0.86-1.02-1.55-2.07-1.55c-1.53,0-2.55,1.19-2.55,3.37c0,1.5,0.76,3.22,2.54,3.22
		c1.26,0,2.24-0.95,2.24-2.1h-2.03v-1.53h3.82v4.93H83.18z"/>
	<polygon points="87.64,102.11 87.64,98.64 84.46,92.94 86.7,92.94 88.63,96.67 90.41,92.94 92.62,92.94 89.55,98.67 89.55,102.11
		"/>
	<path d="M96.72,97.22c1.1,0,1.56-0.57,1.56-1.37c0-0.76-0.46-1.33-1.56-1.33h-1.82v2.69H96.72z M92.98,102.11v-9.17h4.04
		c1.79,0,2.94,1.31,2.94,2.97c0,1.67-1.15,2.87-2.94,2.87h-2.13v3.33H92.98z"/>
	<polygon points="102.78,102.11 102.78,94.54 100.02,94.54 100.02,92.94 107.47,92.94 107.47,94.54 104.69,94.54 104.69,102.11 	"/>
	<path d="M98.8,45.09v3.53v0.42h-1.28c-1.21,0-2.18-0.98-2.18-2.18c0-1.21,0.98-2.18,2.18-2.18h1.28V45.09z M97.71,42.45
		c-0.06,0-0.13-0.01-0.19-0.01c-2.44,0-4.41,1.97-4.41,4.41c0,2.44,1.97,4.41,4.41,4.41c0.06,0,0.13,0,0.19-0.01v0.01h1.12
		c-0.04,0.51-0.35,1.28-1.92,1.28c-2.33,0-2.33-0.47-2.33-0.47v2.53c0,0,1.04,0.37,2.53,0.37c1.49,0,4.01-0.84,4.01-3.71V49.4v-5.08
		v-1.87L97.71,42.45L97.71,42.45z"/>
	<path d="M106.72,51.38v-8.93h-2.31v8.93c0,1.52-1.38,1.22-1.95,1.04v2.14l0.01,0.12C107.18,55.78,106.72,51.38,106.72,51.38"/>
	<path d="M82.19,45.06v3.58v0.4h-1.26c-1.21,0-2.19-0.98-2.19-2.19s0.98-2.19,2.19-2.19h1.26V45.06z M91.13,51.3v-8.85h-2.21v6.59
		h-4.45v-7.41h-2.21v0.82h-1.44v0c-2.38,0.06-4.29,2-4.29,4.4c0,2.36,1.86,4.29,4.19,4.4v0.06h1.55h1.52h0.68v-0.05h4.45v0.05H91.13
		z"/>
	<path d="M99.44,38.85h1.31v-0.77h-1.4l-0.13,0.02c-1.62,0.16-1.53,1.36-1.53,1.36v1.88h0.21h1.13h2.28v-1.26h-2.28
		C99.05,39.14,99.06,38.85,99.44,38.85"/>
	<path d="M83.15,40.47h0.08c0.66,0,1.19-0.54,1.19-1.19c0-0.66-0.54-1.19-1.19-1.19h-0.08c-0.66,0-1.19,0.54-1.19,1.19
		C81.95,39.93,82.49,40.47,83.15,40.47"/>
	<path d="M79.72,40.47h0.08c0.66,0,1.19-0.54,1.19-1.19c0-0.66-0.54-1.19-1.19-1.19h-0.08c-0.66,0-1.19,0.54-1.19,1.19
		C78.53,39.93,79.07,40.47,79.72,40.47"/>
	<path d="M90.78,52.47H90.7c-0.66,0-1.19,0.54-1.19,1.19c0,0.66,0.54,1.19,1.19,1.19h0.08c0.66,0,1.19-0.54,1.19-1.19
		C91.97,53,91.44,52.47,90.78,52.47"/>
	<path d="M87.36,52.47h-0.08c-0.66,0-1.19,0.54-1.19,1.19c0,0.66,0.54,1.19,1.19,1.19h0.08c0.66,0,1.19-0.54,1.19-1.19
		C88.55,53,88.01,52.47,87.36,52.47"/>
</g>
`]
